<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Economía ({{ mostRecentYear }}, USD)</span>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>

        <v-skeleton-loader
          class="mx-auto"
          type="article,article,article"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        {{ errMessage }}
      </v-alert>

      <v-row v-if="!loading && !error">
        <v-col cols="12">
          <bar-chart v-if="chartDataEconomia" :chart-data="chartDataEconomia" :is-dark="isDark"></bar-chart>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div>
        <p class="text-right mt-3 font-weight-bold">
          Fuente información: Elaboración propia basada en INEGI  y proyecciones da datos utilizando modelos fundacionales de inteligencia artificial.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import poblacion from '@/services/poblacion'
import BarChart from './BarChart'

export default {
  name: 'TpEconomia',
  components: {
    BarChart,
  },
  data() {
    return {
      data: {},
      loading: true,
      error: false,
      errMessage: '',
      mostRecentYear: null,
      chartDataEconomia: null,
      isDark: false,
    }
  },
  watch: {
    '$vuetify.theme.dark': function handleThemeChange(newVal) {
      this.isDark = newVal
    },
  },
  mounted() {
    this.isDark = this.$vuetify.theme.dark
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const resp = await poblacion.getEconomia()
        const { data } = resp

        // Transformar los datos recibidos
        const transformedData = {
          Anio: [],
          VentasInt: [],
          VentasIntDestino: [],
          ComprasInt: [],
          ComprasIntOrigen: [],
          NetoComprasInt: [],
          NetoVentasInt: [],
        }

        data.rows.forEach(row => {
          row.cells.forEach(cell => {
            switch (cell.columnId) {
              case 1711717527801732:
                transformedData.Anio.push(cell.displayValue)
                break
              case 6215317155172228:
                transformedData.VentasInt.push(parseInt(cell.displayValue, 10))
                break
              case 3963517341486980:
                transformedData.VentasIntDestino.push(parseInt(cell.displayValue, 10))
                break
              case 8467116968857476:
                transformedData.ComprasInt.push(parseInt(cell.displayValue, 10))
                break
              case 304342644248452:
                transformedData.ComprasIntOrigen.push(parseInt(cell.displayValue, 10))
                break
              case 4807942271618948:
                transformedData.NetoComprasInt.push(parseInt(cell.displayValue, 10))
                break
              case 1776089692327812:
                transformedData.NetoVentasInt.push(parseInt(cell.displayValue, 10))
                break
              default:
                break
            }
          })
        })

        // Calcular el año más reciente
        const mostRecentYear = Math.max(...transformedData.Anio.map(year => parseInt(year, 10)))

        this.data = transformedData
        this.mostRecentYear = mostRecentYear
        this.loading = false
        this.prepareChartData()
      } catch (err) {
        this.error = err.response.data
        this.loading = false
      }
    },
    prepareChartData() {
      this.chartDataEconomia = {
        labels: this.data.Anio.map(anio => `${anio}`),
        datasets: [
          {
            label: 'Ventas Internacionales Principal Producto Exportado',
            backgroundColor: '#028675',
            data: this.data.VentasInt,
          },
          {
            label: 'Ventas Internacionales Principal Destino',
            backgroundColor: '#1a93a7',
            data: this.data.VentasIntDestino,
          },
          {
            label: 'Compras Internacionales Principal Producto Importado',
            backgroundColor: '#a25a94',
            data: this.data.ComprasInt,
          },
          {
            label: 'Compras Internacionales Principal Origen',
            backgroundColor: '#f79c17',
            data: this.data.ComprasIntOrigen,
          },
          {
            label: 'Comercio Internacional Neto Compras Internacionales',
            backgroundColor: '#2da6df',
            data: this.data.NetoComprasInt,
          },
          {
            label: 'Comercio Internacional Neto Ventas Internacionales',
            backgroundColor: '#50ac2c',
            data: this.data.NetoVentasInt,
          },
        ],
      }
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
}
</script>
