<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('0')"
                  >
                    Mostrar todo
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('561510')"
                  >
                    Agencias de viajes
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('561510')"
                  >
                    {{ agencia }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('722412')"
                  >
                    Bares, cantinas y <br />similares
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('722412')"
                  >
                    {{ bares }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('312142')"
                  >
                    Elaboración de bebidas <br>destiladas de agave
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('312142')"
                  >
                    {{ elaboracion }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('321920')"
                  >
                    Fabricación de productos <br>para embalaje y envases <br>de madera
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('321920')"
                  >
                    {{ fabricacion }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('721111')"
                  >
                    Hoteles con otros <br />servicios integrados
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('721111')"
                  >
                    {{ hoteles }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('721112')"
                  >
                    Hoteles sin servicios <br>integrados
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('721112')"
                  >
                    {{ hotelesSn }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('808080')"
                  >
                    Restaurantes
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="filterMarkerCom('808080')"
                  >
                    {{ rest }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <div
          v-show="map"
          ref="googleMap"
          class="google-map"
        ></div>
      </v-col>
    </v-row>
    <p></p>
    <v-divider></v-divider>
    <p></p>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            ref="vuetable"
            :headers="headers"
            :items="establecimientos"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>{{ props.item.Nombre }}</td>
              <td>{{ props.item.Razon_social }}</td>
              <td>{{ props.item.Tipo_vialidad }}</td>
              <td>{{ props.item.Calle }}</td>
              <td>{{ props.item.Num_Exterior }}</td>
              <td>{{ props.item.Colonia }}</td>
            </template>
            <template v-slot:no-results>
              <v-alert
                :value="true"
                color="error"
              >
                No hay resultados para su búsqueda
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import information from '../../assets/images/maps/information.png'
import male from '../../assets/images/maps/male.png'
import parking from '../../assets/images/maps/parking.png'
import sportscar from '../../assets/images/maps/sportscar.png'

export default {
  props: {
    apiKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      google: null,
      total: 0,
      markers: [],
      marker: null,
      map: null,
      pagination: {},
      search: '',
      infoImage: information,
      maleImage: male,
      sportscarImage: sportscar,
      parkingImage: parking,
      mapConfig: {
        center: { lat: 20.885866, lng: -103.837593 },
        zoom: 14,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
      elaboracion: 0,
      fabricacion: 0,
      agencia: 0,
      hoteles: 0,
      hotelesSn: 0,
      bares: 0,
      rest: 0,
      establecimientos: [],
      comercios: [],
      headers: [
        { text: 'Nombre', value: 'Nombre' },
        { text: 'Razón Social', value: 'Razon_social' },
        { text: 'Tipo Vialidad', value: 'Tipo_vialidad' },
        { text: 'Calle', value: 'Calle', sortable: false },
        { text: 'Número Exterior', value: 'Num_Exterior', sortable: false },
        { text: 'Colonia', value: 'Colonia', sortable: false },
      ],
    }
  },

  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null
        || this.pagination.totalItems == null
      ) {
        return 0
      }

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.apiKey,
      libraries: ['visualization'],
    })

    // Promise
    loader
      .load()
      .then(google => {
        this.google = google
      })
      .catch(e => {
        // do something
        console.log(e)
      })
  },

  methods: {
    initMap() {
      if (!this.map && this.google) {
        const mapContainer = this.$refs.googleMap
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
      }
    },
    createMap(dataMap) {
      this.establecimientos = dataMap
      this.comercios = dataMap
      this.initMap()
      let i = 0
      this.deleteMarkers()

      for (i; i < dataMap.length; i += 1) {
        const codigoAct = dataMap[i].CLEE.substring(5, 11)

        if (codigoAct === '312142') {
          this.elaboracion += 1
        } else if (codigoAct === '321920') {
          this.fabricacion += 1
        } else if (codigoAct === '561510') {
          this.agencia += 1
        } else if (codigoAct === '721111') {
          this.hoteles += 1
        } else if (codigoAct === '721112') {
          this.hotelesSn += 1
        } else if (codigoAct === '722412') {
          this.bares += 1
        } else {
          this.rest += 1
        }

        const latitud = Number(dataMap[i].Latitud) || 0
        const long = Number(dataMap[i].Longitud) || 0

        const marker = new this.google.maps.Marker({
          position: { lat: latitud, lng: long },
          title: dataMap[i].Nombre,
          icon: { url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' },
          category: codigoAct,
          map: this.map,
        })

        const nombre = dataMap[i].Nombre
        const estrato = dataMap[i].Estrato
        this.google.maps.event.addListener(marker, 'click', () => {
          const infowindow = new this.google.maps.InfoWindow({
            content: `<b>Nombre:</b>${nombre}<br><b>Personal:</b>${estrato}<br>`,
          })
          infowindow.open(this.map, marker)
        })
        this.markers.push(marker)
      }
    },
    filterMarkerCom(category) {
      let i = 0
      for (i; i < this.markers.length; i += 1) {
        const marker = this.markers[i]
        if (category === '0') {
          marker.setVisible(true)
        } else if (category === '808080' && (marker.category === '722212'
          || marker.category === '722219' || marker.category === '722514'
          || marker.category === '722518' || marker.category === '722511'
          || marker.category === '722513' || marker.category === '722516'
          || marker.category === '466410' || marker.category === '722110')) {
          marker.setVisible(true)
        } else if (marker.category === category) {
          marker.setVisible(true)
        } else {
          marker.setVisible(false)
        }
      }
      this.establecimientos = []
      if (category === '0') {
        this.establecimientos = this.comercios
      } else {
        let j = 0
        for (j; j < this.comercios.length; j += 1) {
          const codigoAct = this.comercios[j].CLEE.substring(5, 11)
          if (category === '808080' && (codigoAct === '722212'
            || codigoAct === '722219' || codigoAct === '722514'
            || codigoAct === '722518' || codigoAct === '722511'
            || codigoAct === '722513' || codigoAct === '722516'
            || codigoAct === '466410' || codigoAct === '722110')) {
            this.establecimientos.push(this.comercios[j])
          } else if (category === codigoAct) {
            this.establecimientos.push(this.comercios[j])
          }
        }
      }
    },
    deleteMarkers() {
      let i = 0
      for (i; i < this.markers.length; i += 1) {
        this.markers[i].setMap(null)
      }
      this.markers = []
    },
  },
}
</script>
<style>
.google-map {
  min-height: 400px;
}
</style>
