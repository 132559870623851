import { Bar, mixins } from 'vue-chartjs'
import numeral from 'numeral'
import Chart from 'chart.js'

const { reactiveProp } = mixins

// Plugin personalizado para dibujar etiquetas de valores de barras
const drawBarValuesPlugin = {
  afterDatasetsDraw(chart) {
    const { ctx } = chart

    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i)
      if (!meta.hidden) {
        const numBars = meta.data.length
        meta.data.forEach((element, index) => {
          const value = dataset.data[index]
          ctx.fillStyle = chart.options.scales.yAxes[0].ticks.fontColor

          // Formatear el valor como porcentaje si es menor o igual a 100 (CUIDADO PUEDE FALLAR, SI UN VALOR NO PORCENTUAL ES MENOR O IGUAL A 100)
          let dataString
          if (value <= 100) {
            dataString = new Intl.NumberFormat('en-US', {
              style: 'percent',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value / 100)
          } else {
            dataString = numeral(value).format('0,0.')
          }

          ctx.textAlign = 'center'
          ctx.textBaseline = 'bottom'
          const padding = 0
          const position = element.tooltipPosition()
          const offsetX = 0
          let offsetY = 3.2

          // Intercalar la altura de las etiquetas si hay más de 6 barras
          if (numBars > 6) {
            offsetY += (index % 2 === 0) ? -2 : -2
          }

          ctx.fillText(dataString, position.x + offsetX, position.y + offsetY - padding)
        })
      }
    })
  },
}

Chart.plugins.register(drawBarValuesPlugin)

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultOptions: this.getOptions(this.isDark),
    }
  },
  watch: {
    isDark(newVal) {
      this.defaultOptions = this.getOptions(newVal)
      this.renderChart(this.chartData, { ...this.defaultOptions, ...this.options })
    },
  },
  methods: {
    getOptions(isDark) {
      const fontColor = isDark ? '#c9c4df' : '#8e8895'

      return {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: value => numeral(value).format('0,0.'),
              fontColor,
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{
            ticks: {
              fontColor,
            },
            gridLines: {
              display: false,
            },
          }],
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || ''
              if (label) {
                label += ': '
              }

              // Formatear el valor como porcentaje si es menor o igual a 100
              if (tooltipItem.yLabel <= 100) {
                label += new Intl.NumberFormat('en-US', {
                  style: 'percent',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(tooltipItem.yLabel / 100)
              } else {
                label += numeral(tooltipItem.yLabel).format('0,0.')
              }

              return label
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    },
  },
  mounted() {
    this.renderChart(this.chartData, { ...this.defaultOptions, ...this.options })
  },
}
