<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Vivienda y Calidad de Vida ({{ mostRecentYear }})</span>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>

        <v-skeleton-loader
          class="mx-auto"
          type="article,article,article,article"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        {{ errMessage }}
      </v-alert>

      <v-row v-if="!loading && !error">
        <v-col cols="12" class="d-flex justify-center">
          <bar-chart v-if="chartDataNumeroViviendas" :chart-data="chartDataNumeroViviendas" :is-dark="isDark">
          </bar-chart>
        </v-col>
        <v-col cols="12">
          <bar-chart v-if="chartDataViviendas" :chart-data="chartDataViviendas" :is-dark="isDark"></bar-chart>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div class="mt-3">
        <p class="text-right mt-3 font-weight-bold">
          Fuente información: Elaboración propia basada en INEGI, CONAPO y proyecciones da datos utilizando modelos fundacionales de inteligencia artificial.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import poblacion from '@/services/poblacion'
import BarChart from './BarChart'

export default {
  name: 'TpVivienda',
  components: {
    BarChart,
  },
  data() {
    return {
      vdata: {},
      loading: true,
      error: false,
      errMessage: '',
      mostRecentYear: null,
      chartDataNumeroViviendas: null,
      chartDataViviendas: null,
      isDark: false,
    }
  },
  watch: {
    '$vuetify.theme.dark': function handleThemeChange(newVal) {
      this.isDark = newVal
    },
  },
  mounted() {
    this.isDark = this.$vuetify.theme.dark
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const resp = await poblacion.getViviendas()
        const { data } = resp

        // Transformar los datos recibidos
        const transformedData = {
          Anio: [],
          NumeroViviendas: [],
          Electricidad: [],
          Agua: [],
          Banio: [],
          Tv: [],
          Computadora: [],
          Telefono: [],
          Celular: [],
          Internet: [],
          TvPaga: [],
        }

        data.rows.forEach(row => {
          row.cells.forEach(cell => {
            const value = cell.displayValue.replace(',', '.').replace('%', '')
            switch (cell.columnId) {
              case 498562407550852:
                transformedData.Anio.push(cell.displayValue)
                break
              case 5002162034921348:
                transformedData.NumeroViviendas.push(parseInt(cell.displayValue, 10))
                break
              case 2750362221236100:
                transformedData.Electricidad.push(parseFloat(value))
                break
              case 7253961848606596:
                transformedData.Agua.push(parseFloat(value))
                break
              case 1624462314393476:
                transformedData.Banio.push(parseFloat(value))
                break
              case 6128061941763972:
                transformedData.Tv.push(parseFloat(value))
                break
              case 2211902171336580:
                transformedData.Computadora.push(parseFloat(value))
                break
              case 6715501798707076:
                transformedData.Telefono.push(parseFloat(value))
                break
              case 4463701985021828:
                transformedData.Celular.push(parseFloat(value))
                break
              case 8967301612392324:
                transformedData.Internet.push(parseFloat(value))
                break
              case 65655473917828:
                transformedData.TvPaga.push(parseFloat(value))
                break
              default:
                break
            }
          })
        })

        // Calcular el año más reciente
        const mostRecentYear = Math.max(...transformedData.Anio.map(year => parseInt(year, 10)))

        this.vdata = transformedData
        this.mostRecentYear = mostRecentYear
        this.loading = false
        this.prepareChartData()
      } catch (err) {
        this.error = err.response.data
        this.loading = false
      }
    },
    prepareChartData() {
      this.chartDataNumeroViviendas = {
        labels: this.vdata.Anio.map(anio => `${anio}`),
        datasets: [
          {
            label: 'Número de viviendas',
            backgroundColor: '#028675',
            data: this.vdata.NumeroViviendas,
          },
        ],
      }
      this.chartDataViviendas = {
        labels: this.vdata.Anio.map(anio => `Porcentaje de viviendas con servicios (${anio})`),
        datasets: [
          {
            label: 'Electricidad',
            backgroundColor: '#028675',
            data: this.vdata.Electricidad,
          },
          {
            label: 'Agua entubada',
            backgroundColor: '#1a93a7',
            data: this.vdata.Agua,
          },
          {
            label: 'Baño',
            backgroundColor: '#a25a94',
            data: this.vdata.Banio,
          },
          {
            label: 'Televisión',
            backgroundColor: '#2da6df',
            data: this.vdata.Tv,
          },
          {
            label: 'Computadora',
            backgroundColor: '#50ac2c',
            data: this.vdata.Computadora,
          },
          {
            label: 'Teléfono fijo',
            backgroundColor: '#f79c17',
            data: this.vdata.Telefono,
          },
          {
            label: 'Teléfono celular',
            backgroundColor: '#5bc0de',
            data: this.vdata.Celular,
          },
          {
            label: 'Internet',
            backgroundColor: '#5cb85c',
            data: this.vdata.Internet,
          },
          {
            label: 'Televisión de paga',
            backgroundColor: '#428bca',
            data: this.vdata.TvPaga,
          },
        ],
      }
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
}
</script>
