<template>
  <div>
    <div>
      <v-row>
        <v-col
          cols="12"
          md="3"
        ></v-col>
        <v-col
          cols="12"
          offset="1"
          md="2"
        >
          <v-btn color="primary" @click="filterMarker('actual')">
            Actual
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn color="primary" @click="filterMarker('crecimiento')">
            Crecimiento
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <p></p>
    <div v-show="map"
         ref="googleMap"
         class="google-map"
    ></div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import information from '../../assets/images/maps/information.png'
import male from '../../assets/images/maps/male.png'
import parking from '../../assets/images/maps/parking.png'
import sportscar from '../../assets/images/maps/sportscar.png'

export default {
  props: {
    apiKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      google: null,
      total: 0,
      markers: [],
      map: null,
      infoImage: information,
      maleImage: male,
      sportscarImage: sportscar,
      parkingImage: parking,
      mapConfig: {
        center: { lat: 20.885866, lng: -103.837593 },
        zoom: 14,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
      actualM: false,
      crecimiento: false,
    }
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.apiKey,
      libraries: ['visualization'],
    })

    // Promise
    loader
      .load()
      .then(google => {
        this.google = google
      })
      .catch(e => {
        // do something
        console.log(e)
      })
  },

  methods: {
    initMap() {
      if (!this.map && this.google) {
        const mapContainer = this.$refs.googleMap
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
      }
    },
    createMap(dataMap) {
      this.initMap()
      let i = 0
      this.deleteMarkers()
      for (i; i < dataMap.length; i += 1) {
        const latitud = Number(dataMap[i].Lat) || 0
        const longitud = Number(dataMap[i].Long) || 0
        let urlM = ''

        switch (dataMap[i].Icono) {
          case 'actual':
            urlM = this.infoImage
            break
          case 'conteo personas':
            urlM = this.maleImage
            break
          case 'conteo autos':
            urlM = this.sportscarImage
            break
          case 'estacionamientos':
            urlM = this.parkingImage
            break
          default:
            urlM = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
            break
        }
        const marker = new this.google.maps.Marker({
          position: { lat: latitud, lng: longitud },
          icon: { url: urlM },
          filtro: dataMap[i].Filtro,
          map: this.map,
        })

        const iconMap = dataMap[i].Icono
        this.google.maps.event.addListener(marker, 'click', () => {
          const infowindow = new this.google.maps.InfoWindow({
            content: `<b>Nombre:</b>${iconMap}<br>`,
          })
          infowindow.open(this.map, marker)
        })
        this.markers.push(marker)
      }
    },
    deleteMarkers() {
      let i = 0
      for (i; i < this.markers.length; i += 1) {
        this.markers[i].setMap(null)
      }
      this.markers = []
    },
    filterMarker(fill) {
      let i = 0
      let filtro = ''
      if (fill === 'actual' && !this.actualM) {
        this.actualM = true
      } else if (fill === 'crecimiento' && !this.crecimientoM) {
        this.crecimientoM = true
      } else {
        filtro = 'todo'
        this.actualM = false
        this.crecimientoM = false
      }
      for (i; i < this.markers.length; i += 1) {
        const marker = this.markers[i]
        if (filtro === 'todo') {
          marker.setVisible(true)
        } else if (marker.filtro === fill) {
          marker.setVisible(true)
        } else {
          marker.setVisible(false)
        }
      }
    },
  },
}
</script>
<style>
.google-map {
  min-height: 400px;
}
</style>
