<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Proyecciones PIB</span>
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ error }}
      </v-alert>
      <div class="small">
        <bar-chart
          v-if="datacollection"
          :chart-data="datacollection"
          :is-dark="isDark"
        ></bar-chart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import BarChart from './BarChart'
import poblacion from '@/services/poblacion'

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
      isDark: false,
    }
  },
  watch: {
    '$vuetify.theme.dark': function handleThemeChange(newVal) {
      this.isDark = newVal
    },
  },
  mounted() {
    this.isDark = this.$vuetify.theme.dark
    this.fillData()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData() {
      const chartData = []
      const chartLabels = []

      poblacion.getProyeccionPib().then(resp => {
        resp.data.map(value => {
          chartData.push(value.Valor)
          chartLabels.push(value.Anio)
          this.loading = false

          return true
        }, {})
        this.datacollection = {
          labels: chartLabels,
          datasets: [
            {
              label: 'PIB',
              backgroundColor: '#a25a94',
              data: chartData,
            },
          ],
        }
      }).catch(err => {
        this.error = err.response.data
        this.loading = false
      })
    },
  },
}
</script>
